import React from "react";
import { Dialog } from "@material-ui/core";
import MovementReport from "../Report/MovementReport";
import Slide from "@material-ui/core/Slide";
import { PrinterContext } from "../../contexts/PrinterContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
// Apague esse comentário depois
class PrinterDialog extends React.Component {
  render() {
    return (
      <>
        <Dialog
          fullScreen
          open={this.context.open}
          onClose={this.context.handleClose}
          TransitionComponent={Transition}
        >
          <MovementReport
            title={this.context.title}
            headerMainText={this.context.headerMainText}
            onClickCloseIcon={this.context.onClickCloseIcon}
            onClickBackToInit={this.context.onClickBackToInit}
            disableSellerButton={this.context.disableSellerButton}
            disableClientButton={this.context.disableClientButton}
            onClickSellerButton={this.context.onClickSellerButton}
            onClickClientButton={this.context.onClickClientButton}
            headerSecondaryText={this.context.headerSecondaryText}
          >
            <textarea
              style={{
                backgroundColor: "rgb(245, 245, 245)",
                width: "100%",
                transform: "scaleX(0.9)",
                border: "none",
                margin: "0px",
                padding: "15px",
                display: "block",
                position: "absolute",
                resize: "none",
                height: "60vh",
                overflowY: "scroll",
              }}
              value={this.context.content}
              disabled
            />
            <img
              src={this.context.imagemLogo}
              alt="Print invoice"
              style={{
                position: "absolute",
                float: "right",
                height: "auto",
                right: "20px",
                top: "160px",
                width: "150px",
              }}
            ></img>
          </MovementReport>
        </Dialog>
      </>
    );
  }
}
PrinterDialog.contextType = PrinterContext;
export default PrinterDialog;
